import invariant from 'invariant';
import { of } from 'rxjs';
import type { Observable } from 'rxjs';
import type { RouteAuthorization, ApplicationRoute, Parameters } from 'contracts';
import toArray from 'utils/to-array';
import tree from 'utils/tree';
import is from 'utils/is';

type Module = Record<string, RouteAuthorization | Array<RouteAuthorization>>;

const cache = new Map<RouteAuthorization['name'], RouteAuthorization>();

const addModule = (module: Module): void => {
  tree.flatten(toArray(module.default)).forEach((r: RouteAuthorization) => {
    invariant(!cache.has(r.name), `[ROUTER AUTH]: Authorization for route "${r.name}" is already listed.`);

    cache.set(r.name, r);
  });
};

if (process.env.NODE_ENV === 'test') {
  const defaultAuthorizations: Array<RouteAuthorization> = [
    {
      name: 'placeholder',
      authorization$: of(true),
    },
    {
      name: 'search',
      authorization$: of(true),
    },
    {
      name: 'subscriptions',
      authorization$: of(true),
    },
  ];

  addModule({ default: defaultAuthorizations });

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const files = require('glob').globSync('src/pages/**/@@global/authorization.*(tsx|ts)') as string[];

  files.forEach((file) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/restrict-template-expressions
    const filepath = file.replace(`src${require('path').sep}`, '');

    // eslint-disable-next-line @typescript-eslint/no-require-imports,import/no-dynamic-require
    addModule(require(filepath) as Module);
  });
} else {
  const context = require.context('../../pages', true, /\/@@global\/authorization\.ts$/u);

  context
    .keys()
    .filter((path) => path.startsWith('./'))
    .map(context)
    .forEach((module) => {
      addModule(module as Module);
    });
}

const authorization = {
  get(name: keyof ApplicationRoute): RouteAuthorization | undefined {
    return cache.get(name);
  },
  has(name: keyof ApplicationRoute): boolean {
    return cache.has(name);
  },
  getAuthorization$<T extends keyof ApplicationRoute = keyof ApplicationRoute>(
    name: T,
    params?:
      | ApplicationRoute[T]['params']
      | Partial<ApplicationRoute[T]['searchParams']>
      | Partial<ApplicationRoute[T]['hashParams']>
  ): Extract<RouteAuthorization['authorization$'], Observable<unknown>> | undefined {
    const authorization$ = authorization.get(name)?.authorization$;

    return is.func(authorization$) ? authorization$(params as Parameters) : authorization$;
  },
};

export default authorization;

import authentication from 'services/authentication';
import application from 'services/application';
import language from 'services/i18n/language';
import shallowEqual from 'utils/shallow-equal';
import environment from 'utils/environment';

import gTag from './g-tag';
import { TRACKING_ID } from './tracking-id';
import { convertKeyToSnakeCase } from './utils';

interface Parameters {
  userId: string;
  viewType: string;
  userAccountType: string;
  limitedAccess: boolean;
  authenticated: boolean;
  multiplePerformers: boolean;
  testAccount: boolean;
  language: string;
}

interface GAConfig extends Parameters {
  clientId: 'client_id';
  application: 'PWA Nautilus';
  trafficType: string;
}

let customParams: Parameters;

let initialized = false;
let activated = false;

const init = (params: Partial<Parameters> = {}): void => {
  initialized = true;

  customParams = convertKeyToSnakeCase({
    userId: params.userId ?? '',
    viewType: 'model',
    userAccountType: 'user',
    language: language.current,
    limitedAccess: false,
    multiplePerformers: false,
    testAccount: false,
    authenticated: authentication.isAuthenticated(),
    ...params,
  });

  gTag('js', new Date());
  gTag(
    'config',
    TRACKING_ID,
    convertKeyToSnakeCase({
      sendPageView: false,
      transportType: 'beacon',
      appName: application.current,
      userId: params.userId,
      userProperties: convertKeyToSnakeCase({
        application: 'PWA Nautilus',
        clientId: 'client_id',
        trafficType: environment.current,
        ...customParams,
      } satisfies GAConfig),
    })
  );

  if (!activated) {
    gTag('set', 'allow_google_signals', false);
  }
};

const activate = (): void => {
  if (activated) {
    return;
  }

  activated = true;

  gTag('set', 'allow_google_signals', true);
};

const persist = (params: Partial<Parameters>): void => {
  const normalisedParams = convertKeyToSnakeCase(params);

  if (!initialized || shallowEqual(normalisedParams, customParams)) {
    return;
  }

  customParams = convertKeyToSnakeCase({ ...customParams, ...normalisedParams });

  gTag('set', 'user_properties', customParams);
};

export type { Parameters };
export { persist, activate };
export default init;

import { paths } from 'services/routing/routes';
import parse from 'utils/parse';
import type {
  ApplicationRoute,
  NavigationAction,
  SearchParameters,
  Parameters as RouteParameters,
  HashParameters,
} from 'contracts';
import type { NavigationLinkAction, NavigationLink } from 'contracts/internals/navigation';

import createRoutePath from './create-route-path';

/**
 * @callback MountLink
 * @param {string} path
 * @param {SearchParameters} searchParams
 * @param {HashParameters} hashParams
 * @returns {NavigationLinkAction}
 */
type MountLink = <K extends keyof ApplicationRoute>(
  path: string,
  searchParams: SearchParameters,
  hashParams: HashParameters
) => NavigationLinkAction<K>;

const mountLink: MountLink = <K extends keyof ApplicationRoute>(
  path: string,
  searchParams: SearchParameters,
  hashParams: HashParameters
) => {
  return (params = {} satisfies Parameters<NavigationAction<K>>[0]) => {
    const mountedPath = createRoutePath(path, params as RouteParameters, searchParams, hashParams);

    if (!mountedPath?.pathname) return '';

    return parse.url(mountedPath.pathname, mountedPath.search, mountedPath.hash);
  };
};

const link: NavigationLink = Object.entries(paths).reduce(
  (acc, [name, { path, searchParams = [], hashParams = [] }]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[name] = mountLink(path ?? '', searchParams, hashParams);

    return acc;
  },
  {} as NavigationLink
);

export default link;

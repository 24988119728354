import React from 'react';
import { combineLatest, map, distinctUntilChanged } from 'rxjs';
import type { Observable } from 'rxjs';
import authentication from 'services/authentication';
import navigation, { link } from 'services/navigation';
// eslint-disable-next-line no-restricted-imports
import account from 'store/account';
// eslint-disable-next-line no-restricted-imports
import user from 'store/user';
import is from 'utils/is';
import type { RouteAuthorization, ApplicationRoute } from 'contracts';

import type { Authorization } from './contracts';

interface RouteProtectionElement {
  skeleton: React.ReactElement | null;
  onAuth$: Extract<RouteAuthorization['authorization$'], Observable<unknown>>;
  children: React.ReactNode;
}

const ProtectedRoute: React.FunctionComponent<RouteProtectionElement> = (props) => {
  const { skeleton, onAuth$, children } = props;
  const [authorization, setAuthorization] = React.useState<Authorization>('loading');

  React.useEffect(() => {
    if (!authentication.isAuthenticated()) {
      const isLogin = link.login() === window.location.pathname;

      if (!isLogin) {
        navigation.login({ redirect: encodeURIComponent(window.location.href) });
      }

      return;
    }

    const subscription = combineLatest([
      account.onChange$.pipe(
        map((data) => data?.flags?.registrationPending),
        distinctUntilChanged(),
        map<boolean | undefined, Authorization>((pending) => {
          if (is.nullish(pending)) {
            return 'loading';
          }

          return pending ? 'enrollment-incomplete' : 'granted';
        })
      ),
      onAuth$.pipe(
        map<boolean | undefined, Authorization>((result) => {
          if (is.nullish(result)) {
            return 'loading';
          }

          return result ? 'granted' : 'denied';
        })
      ),
    ])
      .pipe(
        map<Array<Authorization>, Authorization>((result) => {
          if (result.includes('denied')) {
            return 'denied';
          }

          if (result.includes('enrollment-incomplete')) {
            return 'enrollment-incomplete';
          }

          if (result.includes('loading')) {
            return 'loading';
          }

          return result[0];
        })
      )
      .subscribe((result) => {
        setAuthorization(result);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [onAuth$]);

  React.useEffect(() => {
    switch (authorization) {
      case 'enrollment-incomplete':
        navigation.signup();
        break;

      case 'denied': {
        const routeName: keyof ApplicationRoute = user.isStudioView() ? 'models' : 'dashboard';

        navigation[routeName](undefined, 'replace');
        break;
      }

      default:
        break;
    }
  }, [authorization]);

  if (!authentication.isAuthenticated()) {
    return null;
  }

  if (authorization !== 'granted') {
    return skeleton;
  }

  return children as React.ReactElement<unknown, React.JSXElementConstructor<unknown>>;
};

export default ProtectedRoute;

import history from 'services/routing/history';
import { paths } from 'services/routing/routes';
import type {
  ApplicationRoute,
  Navigation,
  NavigationAction,
  SearchParameters,
  Parameters as RouteParameters,
  HashParameters,
} from 'contracts';

import createRoutePath from './create-route-path';

/**
 * @callback MountNavigation
 * @param {string} path
 * @param {SearchParameters} searchParams
 * @param {HashParameters} hashParams
 * @param {boolean} replace
 * @returns {NavigationAction}
 */
type MountNavigation = <K extends keyof ApplicationRoute>(
  path: string,
  searchParams: SearchParameters,
  hashParams: HashParameters,
  replace: boolean
) => NavigationAction<K>;

const mountNavigation: MountNavigation = <K extends keyof ApplicationRoute>(
  path: string,
  searchParams: SearchParameters,
  hashParams: HashParameters,
  replace: boolean
) => {
  return (params = {} satisfies Parameters<NavigationAction<K>>[0], method = undefined) => {
    const shouldReplaceHistory = method ? method === 'replace' : replace;
    const mountedPath = createRoutePath(
      path,
      params as RouteParameters,
      searchParams,
      hashParams,
      shouldReplaceHistory
    );

    if (!mountedPath) {
      return;
    }

    if (shouldReplaceHistory) {
      return history.replace(mountedPath);
    }

    history.push(mountedPath);
  };
};

const navigation: Navigation = Object.entries(paths).reduce(
  (acc, [name, { path, searchParams = [], hashParams = [], replace = false }]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[name] = mountNavigation(path ?? '', searchParams, hashParams, replace);

    return acc;
  },
  {} as Navigation
);

export default navigation;

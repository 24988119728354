enum LiveNotificationEvent {
  // AchievementLevelChanged = 'achievement.level.changed',
  ActionRequestsDisabled = 'performer.action_requests.disabled',
  BattleDisabled = 'performer.battle.disabled',
  BattleEnabled = 'performer.battle.enabled',
  BattleEnded = 'performer.battle.ended',
  ChannelContentCommented = 'channel.content.commented',
  ChannelContentItemRejected = 'performer.channel.content.rejected',
  ChannelContentLiked = 'channel.content.liked',
  CompetitorRewarded = 'competition.competitor.rewarded',
  ExcludedAwardGame = 'competition.performer.excluded',
  FanclubMemberResubscribed = 'fanclub.member_resubscribed',
  FanclubMemberSubscribed = 'fanclub.member_subscribed',
  FanclubMemberUnsubscribed = 'fanclub.member_unsubscribed',
  FavouriteAdded = 'performer.member.favourite.added',
  HotShowEnded = 'performer.hot_show.ended',
  ImagefolderSubscribed = 'mycontent.imagefolder.subscription.created',
  InstantPayoutsUpdated = 'payout_settings.instant_payouts.updated',
  InternalPartnerConsentRevoked = 'internal_partner.consent.revoked',
  InteractiveToyDisabled = 'performer.interactive_toy.disabled',
  LoyalfansRegistrationStatusUpdated = 'loyalfans.registration_status.updated',
  MessengerMemberMessage = 'performer.member.message',
  MessengerPerformerMessage = 'member.performer.message',
  MessengerPerformerMessageSent = 'performer.message.sent',
  MessengerMemberMessageSent = 'member.message.sent',
  MessengerMessageUpdated = 'messenger.message-updated',
  MobileLiveDisabled = 'performer.mobile_live.disabled',
  MobileLiveEnabled = 'performer.mobile_live.enabled',
  MyStoryItemActivated = 'performer.story.item.activated',
  MyStoryItemRejected = 'performer.story.item.rejected',
  MyStoryItemStored = 'performer.story.item.stored',
  PerformerMassMessageSent = 'performer.mass_message.sent',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PerformerMessageSent = 'performer.message.sent',
  PerformerStatusUpdated = 'performer.status.updated',
  PerformerSurvey = 'performer.survey_notification',
  PerformerThreadSeenByMember = 'performer.messenger.v3.thread.seen.by_member',
  PerformerShopPaymentOrderPlaced = 'performer.shop.payment_order.placed',
  ProfilePictureAccepted = 'performer.channel.profile_picture.status.accepted',
  ProfilePictureRejected = 'performer.channel.profile_picture.status.rejected',
  PerformerPerformerReferralConnectionCreated = 'performer.performer_referral.connection.created',
  PerformerPerformerReferralConnectionCut = 'performer.performer_referral.connection.cut',
  PerformerPerformerReferralConsentRevoked = 'performer.performer_referral.consent.revoked',
  PromotionTeaserStatusAccepted = 'promotion.teaser.status.accepted',
  PromotionTeaserStatusRejected = 'promotion.teaser.status.rejected',
  PromotionTeaserParticipantAdded = 'promotion.teaser.participant.added',
  PromotionTeaserParticipantConfirmationRequired = 'promotion.teaser.participant.confirmation_required',
  PromotoolsPromotionContentStatusAccepted = 'promotools.promotion_content.status.accepted',
  PromotoolsPromotionContentStatusRejected = 'promotools.promotion_content.status.rejected',
  PromotionVideoStatusChanged = 'promotion.video.status.changed',
  RecurrentSubscriptionCreated = 'recurrent_subscription.model.subscription.created',
  RecurrentSubscriptionInactivated = 'subscription.subscription.inactivated',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ReferralConnectionCreated = 'performer.performer_referral.connection.created',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ReferralConnectionCut = 'performer.performer_referral.connection.cut',
  StorySubscription = 'my_story.story.subscription.created',
  VideoCallDisabled = 'performer.video_call.disabled',
  VideoCallEnabled = 'performer.video_call.enabled',
  VideoSubscribed = 'mycontent.video.subscription.created',
  DirectPayoutPending = 'direct_payout.pending',
  DirectPayoutApproved = 'direct_payout.approved',
  DirectPayoutDeleted = 'direct_payout.deleted',
  DirectPayoutDisabledBySupport = 'direct_payout.disabled_by_support',
}

interface WebSocketHeaderResponse<E> {
  event: E;
  'eds-client': string;
  'eds-endpoint': string;
  'eds-notification-id': string;
}

type WebSocketResponse<E, T> = [header: WebSocketHeaderResponse<E>, body: T];

export type { WebSocketResponse, WebSocketHeaderResponse };
export { LiveNotificationEvent };

import { defer, retry, map, switchMap, iif, of, tap, distinctUntilChanged, interval, from } from 'rxjs';
import type { Observable } from 'rxjs';
import type { UserDetailsStudio } from 'services/api/proxy-account-services/data-contracts';
import authentication from 'services/authentication';
import { AccountType } from 'services/graphql/generated/types';
import apolloClient from 'services/graphql';
import is from 'utils/is';
import date, { type DateInput } from 'utils/date';
import retryWhenOnline from 'utils/rxjs/retry-when-online';

import Store from '../store';

import { GetAccountData } from './account.graphql';
import type { GetAccountDataQuery } from './account.contracts';

const accountTypeMap: Record<AccountType, AccountStore['type']> = {
  [AccountType.Model]: 'model',
  [AccountType.Single]: 'single',
  [AccountType.Studio]: 'studio',
  [AccountType.User]: 'user',
};

type StudioStatus = UserDetailsStudio['status'];

interface AccountStore {
  /**
   * Registration of:
   * - Performer single account = "single".
   * - Studio = "studio".
   * - When studio is registering new performer via add model flow = "model".
   */
  type: 'user' | 'single' | 'model' | 'studio';
  userId: number;
  gender: 'male' | 'female' | undefined;
  birthday: DateInput;
  studioId: number;
  studioStatus: StudioStatus;
  studioName: string;
  studioOwnerUserId: number;
  defaultPerformerId: number | undefined;
  email: string;
  personFirstName: string;
  flags: {
    isTestAccount: boolean;
    emailConfirmed: boolean;
    registrationPending: boolean;
    registrationFlowId: string | undefined;
    isLimitedAccess: boolean;
    personalDataDownloadRequestStatus: string | undefined;
    hasMultiplePerformers: boolean;
    studioCertified: boolean;
    payoutOptionAdded: boolean;
  };
  settings: {
    hasLimitedAccess: boolean;
    marketingCommunicationConsent: boolean;
  };
}

const fetchAccountData$ = defer(() =>
  from(
    apolloClient.query<GetAccountDataQuery>({
      query: GetAccountData,
      context: {
        important: true,
        headers: {
          'X-Actor-Type': 'user',
        },
      },
    })
  )
).pipe(
  retryWhenOnline(),
  retry({ count: 3, delay: (err, count) => interval(count * 1000), resetOnSuccess: true }),
  map((response) => response?.data?.me),
  switchMap((accountData) => {
    if (is.nullish(accountData) || is.nullish(accountData.studio)) {
      return of(undefined);
    }

    const { defaultPerformerId, id, studio, persons } = accountData;

    const mappedAccountData: AccountStore = {
      type: accountTypeMap[studio.owner.type],
      userId: parseInt(id, 10),
      gender: persons?.[0].gender ?? undefined,
      birthday: persons?.[0].birthDate ? date(persons?.[0].birthDate).toISODateString() : '',
      studioId: parseInt(studio.id, 10),
      studioName: studio.name,
      studioStatus: studio?.status || 'unknown',
      defaultPerformerId: defaultPerformerId ?? undefined,
      email: studio.owner.email || '',
      personFirstName: persons?.[0].firstName || '',
      studioOwnerUserId: Number(studio.owner.id),
      flags: {
        emailConfirmed: studio.owner.flags.emailConfirmed,
        registrationFlowId: studio.owner.flags.registrationFlowId || undefined,
        registrationPending: studio.owner.flags.registrationPending,
        isTestAccount: studio.owner.flags.isTestAccount,
        isLimitedAccess: studio.owner.flags.isLimitedAccess,
        personalDataDownloadRequestStatus: studio.owner.flags.personalDataDownloadRequestStatus || undefined,
        hasMultiplePerformers: studio.flags.hasMultiplePerformers,
        studioCertified: studio.flags.studioCertified,
        payoutOptionAdded: studio.flags.payoutOptionAdded,
      },
      settings: {
        hasLimitedAccess: studio.owner.hasLimitedAccess,
        marketingCommunicationConsent: Boolean(studio.owner.marketingCommunicationConsent.status),
      },
    };

    return of(mappedAccountData);
  })
);

class Account extends Store<AccountStore | undefined> {
  source$ = authentication.event.onChange$.pipe(
    // flags the user store as loading
    tap(() => {
      super.meta.setLoading(true);
    }),
    // if user is authenticated, then API call is made to request
    // otherwise, it is set back to undefined
    switchMap((authenticated) => iif(() => authenticated, fetchAccountData$, of(undefined))),
    switchMap((accountData) => {
      if (is.nullish(accountData)) {
        return of(this.initialState);
      }

      return of(accountData);
    })
  );

  constructor() {
    super({
      name: 'account',
      initialState: undefined,
    });
  }

  delayStoreUpdateWhen = (): boolean => {
    return is.nullish(this.data);
  };

  /**
   * Flags if user account has a completed registration process.
   * @return {boolean}
   */
  get hasEnrollmentCompleted(): boolean {
    return this.data?.flags?.registrationPending === false;
  }

  /**
   * Flags user account registration process as completed.
   * @return {boolean}
   */
  markEnrollmentAsCompleted(): void {
    this.set('flags', {
      ...this.data?.flags,
      registrationPending: false,
      registrationFlowId: undefined,
    });
  }

  isStudio(): boolean {
    return this.data?.type === 'studio';
  }

  setTestAccount(value: boolean): void {
    this.set('flags', {
      ...this.data?.flags,
      isTestAccount: value,
    });
  }

  get onPayoutOptionChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map((data) => !data?.flags?.payoutOptionAdded ?? false),
      distinctUntilChanged()
    );
  }

  setEmail(email: string): void {
    this.set('email', email);
  }

  setMarketingCommunicationConsent(value: boolean): void {
    this.set('settings', {
      ...this.data?.settings,
      marketingCommunicationConsent: value,
    });
  }

  hasStudioClosedStatus(): boolean {
    return this.data?.studioStatus === 'closed';
  }
}

export type { AccountStore };
export default new Account();

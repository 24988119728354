declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Array<any>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gTag(...args: unknown[]): void {
  const isDevEnv = ['dev', 'development'].includes(process.env.NODE_ENV!);

  if (isDevEnv) {
    return;
  }

  if (!window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
  }

  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export default gTag;

import React from 'react';
import { useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import routes from 'services/routing/routes';
import authorization from 'services/routing/authorization';
import location from 'services/routing/location';
import render from 'utils/render';
import type { Route, ApplicationRoute } from 'contracts';

import ProtectedRoute from './protected-route';
import ErrorBoundary from './error-boundary';

const InjectRoute: React.FunctionComponent = () => {
  const element = useRoutes(routes as Array<RouteObject>)!;
  const { name, skeleton } = element.props.match.route as Route & { name: keyof ApplicationRoute };

  const withErrorBoundary = (children: React.ReactNode): React.ReactElement => (
    <ErrorBoundary fallback={skeleton || null}>{children}</ErrorBoundary>
  );

  if (!authorization.has(name)) {
    return withErrorBoundary(<React.Suspense fallback={skeleton || null}>{element}</React.Suspense>);
  }

  return withErrorBoundary(
    <ProtectedRoute skeleton={skeleton || null} onAuth$={authorization.getAuthorization$(name, location.params())!}>
      {render.element(element)}
    </ProtectedRoute>
  );
};

export default React.memo(InjectRoute);

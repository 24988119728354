import is from 'utils/is';

const REGEXP_CAPTURE_GROUPS = /:[\w-]+\([\w|-]+\)\??/g;
const REGEXP_CAPTURE_GROUP_STRUCTURE = /(?<param>:[\w-]+)\((?<list>[\w|-]+)\)(?<optional>\?)?/;
const REGEXP_PRUNE_SLASHES = /\/(?=\/)|(\/)$/gm;

const OPTIONAL_CAPTURING_GROUP_SIGNATURE = ')?';

const createVariants = (path: string): Array<string> => {
  const captureGroups = path.match(REGEXP_CAPTURE_GROUPS);

  if (!captureGroups) return [path];

  const chunks = path.split('/');

  // remove optional capturing groups
  const withoutOptionalCapturingGroups = chunks
    .filter((chunk) => !chunk.endsWith(OPTIONAL_CAPTURING_GROUP_SIGNATURE))
    .join('/');

  // create path variants by removing a different optional capturing group on each iteration
  const variants = captureGroups.map((group) => {
    if (!group.endsWith(OPTIONAL_CAPTURING_GROUP_SIGNATURE)) {
      return path;
    }

    return path.replace(group, '').replace(REGEXP_PRUNE_SLASHES, '');
  });

  const result = captureGroups.reduce(
    (acc, group) => {
      const { list } = group.match(REGEXP_CAPTURE_GROUP_STRUCTURE)!.groups!;
      const options = list.split('|');

      return acc.map((entry) => options.map((option) => entry.replace(group, option))).flat();
    },
    [withoutOptionalCapturingGroups, path, ...variants] as Array<string>
  );

  return Array.from(new Set(result)).sort();
};

const regexpPathToReactRouter = (path: string | undefined): Array<string> => {
  if (is.nullish(path)) {
    return [path!];
  }

  return createVariants(path);
};

export default regexpPathToReactRouter;

import {
  init as initSentry,
  browserTracingIntegration,
  sessionTimingIntegration,
  replayIntegration,
} from '@sentry/react';
import settings from 'configurations/application';
import environment from 'utils/environment';

const dsn = ['dev', 'development'].includes(process.env.NODE_ENV!) ? undefined : settings.envVars.sentryDsn;

/**
 * Sentry initialization.
 * {@link https://docs.sentry.io/platforms/javascript/configuration/options/}
 */
const init = (): void => {
  initSentry({
    dsn,
    integrations: [
      browserTracingIntegration(),
      sessionTimingIntegration(),
      replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    maxBreadcrumbs: 25,
    attachStacktrace: true,
    environment: environment.current,
    /**
     * Filter error from being collected.
     * @desc Use string for partial matches.
     * If an exact match is needed, use regex patterns instead.
     * {@link https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-}
     */
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      /Loading \w*\s?chunk \d+ failed/,
      'Failed to fetch',
      'Script load failed',
      'Load failed',
      'Request aborted',
      'authentication failed',
      'AbortError: The operation was aborted',
      'AbortError: Share canceled',
      'Operation has been aborted',
      'AbortError: The play() request was interrupted by a call to pause()',
      'Abort due to cancellation of share',
      'The request is not allowed by the user agent or the platform',
      'An error occurred. Please try again',
      'captured as promise rejection',
      'Non-Error promise rejection captured with value',
      'Refused to evaluate a string as JavaScript',
      'teads_tracking_events_sent.push is not a function',
      "Failed to execute 'insertBefore' on 'Node'",
      "Failed to execute 'removechild' on 'Node'",
      'Unexpected EOF',
      'The object can not be found here',
      'Request failed with status code',
      /interrupted by client/i,
      /network error/i,
      /rejected/i,
      /cancelled/i,
      /websocket error/,
      /timeout exceeded/,
      /timeout/,
      /ib/,
      /\s?/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  });
};

export default init;

import Cookies from 'js-cookie';
import location from 'services/routing/location';
import is from 'utils/is';

import ServiceBase from '../service-base';

import type { FeatureFlags } from './flags';
import { flags, parse } from './flags';
import { cookiesAttributes, normalizeValue } from './utils';

class FeatureToggle extends ServiceBase<Partial<FeatureFlags>> {
  name = 'feature-toggle';

  private readonly flags = Object.keys(flags);

  constructor() {
    super();

    this.init();
  }

  private readUrlSearchParams(): Partial<FeatureFlags> {
    const searchParams = location.searchParams(true);

    return Object.keys(searchParams).reduce((acc, flag) => {
      if (!this.flags.includes(flag)) return acc;

      const value = normalizeValue(searchParams[flag]);

      Cookies.set(flag, value, cookiesAttributes());

      return { ...acc, [flag]: parse(value) };
    }, {} as FeatureFlags);
  }

  private readCookies(): Partial<FeatureFlags> {
    return this.flags.reduce((acc, flag) => {
      const result = Cookies.get(flag);

      if (is.nullish(result)) return acc;

      return { ...acc, [flag]: parse(result) };
    }, {} as FeatureFlags);
  }

  public init(): void {
    this.set({ ...this.readCookies(), ...this.readUrlSearchParams() });
  }

  public enabled<K extends keyof FeatureFlags>(flag: K): FeatureFlags[K] {
    return this.data?.[flag] === true;
  }

  public disabled<K extends keyof FeatureFlags>(flag: K): FeatureFlags[K] {
    return !this.enabled(flag);
  }

  public toString(): string {
    return Object.entries(this.data ?? {})
      .map(([key, value]) => `${key}=${value ? '1' : '0'}`)
      .join(';');
  }
}

export default new FeatureToggle();

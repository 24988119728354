export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Account = {
  __typename?: 'Account';
  email: Scalars['String']['output'];
  flags: AccountFlags;
  hasLimitedAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  marketingCommunicationConsent: MarketingCommunicationConsent;
  personFirstName: Scalars['String']['output'];
  studio: Studio;
  type: AccountType;
};

export type AccountFlags = {
  __typename?: 'AccountFlags';
  emailConfirmed: Scalars['Boolean']['output'];
  isLimitedAccess: Scalars['Boolean']['output'];
  isTestAccount: Scalars['Boolean']['output'];
  personalDataDownloadRequestStatus?: Maybe<Scalars['String']['output']>;
  registrationFlowId?: Maybe<Scalars['String']['output']>;
  registrationPending: Scalars['Boolean']['output'];
};

export enum AccountType {
  Model = 'model',
  Single = 'single',
  Studio = 'studio',
  User = 'user',
}

export enum Gender {
  Female = 'female',
  Male = 'male',
}

export type MarketingCommunicationConsent = {
  __typename?: 'MarketingCommunicationConsent';
  requestedAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type Me = {
  __typename?: 'Me';
  defaultPerformerId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  persons: Array<Person>;
  studio?: Maybe<Studio>;
  studioId: Scalars['Float']['output'];
};

export type NamedStringProperty = {
  __typename?: 'NamedStringProperty';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Performer = {
  __typename?: 'Performer';
  category?: Maybe<PerformerCategory>;
  displayName: Scalars['String']['output'];
  flags: PerformerFlags;
  id: Scalars['ID']['output'];
  persons: Array<Person>;
  screenName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  studio: Studio;
  studioId: Scalars['Float']['output'];
};

export type PerformerCategory = {
  __typename?: 'PerformerCategory';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<PerformerCategory>;
};

export type PerformerFlags = {
  __typename?: 'PerformerFlags';
  birthdayNotification: Scalars['Boolean']['output'];
  canSeeStatistics: Scalars['Boolean']['output'];
  exclusiveBadgeStatus: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  messengerReadReceipts: Scalars['Boolean']['output'];
  newbie: Scalars['Boolean']['output'];
  soldierAidCampaignParticipant: Scalars['Boolean']['output'];
};

export type Person = {
  __typename?: 'Person';
  birthDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  guidedTourLevel?: Maybe<Scalars['Float']['output']>;
  hasBillingAddress?: Maybe<Scalars['Boolean']['output']>;
  hasBirthday?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isGiftSendingDisabled?: Maybe<Scalars['Boolean']['output']>;
  properties: Array<NamedStringProperty>;
  zodiacSign?: Maybe<ZodiacSign>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Me>;
  performer?: Maybe<Performer>;
};

export type QueryPerformerArgs = {
  id: Scalars['Int']['input'];
};

export type Studio = {
  __typename?: 'Studio';
  flags: StudioFlags;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: Account;
  status: StudioStatus;
};

export type StudioFlags = {
  __typename?: 'StudioFlags';
  hasMultiplePerformers: Scalars['Boolean']['output'];
  payoutOptionAdded: Scalars['Boolean']['output'];
  studioCertified: Scalars['Boolean']['output'];
};

export enum StudioStatus {
  Active = 'active',
  Closed = 'closed',
  New = 'new',
  Pending = 'pending',
  Rejected = 'rejected',
  Unknown = 'unknown',
}

export enum ZodiacSign {
  Aquarius = 'aquarius',
  Aries = 'aries',
  Cancer = 'cancer',
  Capricorn = 'capricorn',
  Gemini = 'gemini',
  Leo = 'leo',
  Libra = 'libra',
  Pisces = 'pisces',
  Sagittarius = 'sagittarius',
  Scorpio = 'scorpio',
  Taurus = 'taurus',
  Virgo = 'virgo',
}

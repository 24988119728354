import type { ServerError } from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { captureException as SentryCaptureException } from 'services/sentry';
import navigation from 'services/navigation';

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path?.toString() || ''}`
      );

      SentryCaptureException(message, {
        tags: {
          class: 'GraphQL',
          severity: 'error',
          origin: 'GraphQL',
        },
      });
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${JSON.stringify(networkError)}`);

    if ((networkError as ServerError).statusCode === 403) {
      navigation.logout();
    }
  }
});

export default errorLink;

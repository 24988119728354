import * as pathToRegexp from 'path-to-regexp';
import invariant from 'invariant';
import type { HistoryLocation, Parameters, SearchParameters, HashParameters } from 'contracts';
import is from 'utils/is';

import createLocationDescriptor from './create-location-descriptor';
import compileQueryStrings from './compile-query-strings';
import { normaliseObjectKeyCase } from './utils';

const mountRoutePath = (
  path: string,
  params: Parameters = {},
  searchParams: SearchParameters = [],
  hashParams: HashParameters = []
): HistoryLocation | undefined => {
  const requiredParams = pathToRegexp
    .parse(path)
    .filter((param) => is.object(param))
    .filter((param) => (param as pathToRegexp.Key).modifier !== '?') as Array<pathToRegexp.Key>;

  const requiredNonMergedParams = requiredParams.filter(({ name }) => is.nullish(params[name]));

  if (requiredNonMergedParams.length) {
    invariant(
      !requiredNonMergedParams.length,
      `[NAVIGATION]: Could not navigate to "${path}".\n` +
        `Reason: missing required parameter(s): \n${requiredNonMergedParams
          .map((p: pathToRegexp.Key) => `- ${p.name}`)
          .join('\n')}`
    );

    return;
  }

  const normalizedParams = normaliseObjectKeyCase(params, false);
  const compiledPath = pathToRegexp.compile(path)(normalizedParams) || '/';

  const compiledHash: string =
    hashParams
      ?.filter((hash: string) => !is.nullish(params[hash]))
      ?.map((hash: string) => `#${hash}`)
      ?.join('') ?? '';

  return createLocationDescriptor(compiledPath, compileQueryStrings(searchParams, normalizedParams), compiledHash);
};

export default mountRoutePath;

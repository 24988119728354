import parse from 'utils/parse';
import type { Parameters } from 'contracts';

const normaliseObjectKeyCase = <T extends Parameters>(source: T, encode = true): T =>
  Object.keys(source).reduce(
    (list, key) => ({ ...list, [parse.toCamelCase(key)]: encode ? encodeURIComponent(source[key]) : source[key] }),
    {} as T
  );

export { normaliseObjectKeyCase };

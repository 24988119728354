import settings from 'configurations/application';

const { languages } = settings.i18n;

type TranslationFile = Record<string, string>;
type Translations = Record<string, () => Promise<Record<string, string>>>;

const translations = languages.reduce((acc, locale) => {
  const list = { ...acc };

  list[locale] = async function Load(): Promise<TranslationFile> {
    try {
      const chunk = (await import(`assets/translations/${locale}.json`)) as { default: string };

      if (process.env.NODE_ENV === 'test') {
        return chunk;
      }

      const filepath = chunk.default;

      return fetch(filepath).then((res) => res.json());
    } catch {
      return {};
    }
  };

  return list;
}, {} as Translations);

export default translations;

type Parser<T> = (value: string) => T;

const parse: Parser<boolean> = (value) => ['1', 'true'].includes(value);

enum flags {
  /**
   * BE (X-Feature-Toggles header) flags
   */
  disableStoryAutoApprovalForTestAccounts = 'disableStoryAutoApprovalForTestAccounts',
  disableEmailAutoconfirmation = 'disableEmailAutoconfirmation',
  /**
   * FE flags
   */
  enableDataTestID = 'enableDataTestID',
  forceAu10TixIdVerification = 'forceAu10TixIdVerification',
  enableQAMode = 'enableQAMode',
  enableUnknownCookieCollection = 'enableUnknownCookieCollection',
  enableConversionScoreGroup2 = 'enableConversionScoreGroup2',
  enableHindi = 'enableHindi',
  enableModelReferral = 'enableModelReferral',
}

type Flags = keyof typeof flags;
type FeatureFlags = Record<Flags, boolean>;

export type { Flags, FeatureFlags };
export { flags, parse };

import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from 'services/routing/history';

import InjectRoute from './inject-route';

const Router: React.FunctionComponent = () => {
  return (
    <HistoryRouter history={history}>
      <InjectRoute />
    </HistoryRouter>
  );
};

export default React.memo(Router);

import invariant from 'invariant';
import type { Route } from 'contracts';

const validate = <T extends Route>(route: T): T => {
  invariant(route.name?.trim(), `[Router] Route "name" cannot be be nullish.\n\n${JSON.stringify(route, null, 2)}`);

  return route;
};

export default validate;

import shallowCopy from 'utils/shallow-copy';
import type { Route } from 'contracts';

/**
 * Normalize Application Route
 * @desc adjust route according to React Router v6 requirements
 * @param {Route} route
 * @return {Route}
 */
const normalizeRoute = (route: Route): Route => {
  const normalized = shallowCopy(route);

  // React Router v6 does not allow to have "path" defined
  // for "index" routes. Thus, we're making sure to remove it.
  if (normalized?.index) {
    delete normalized.path;
  }

  if (Boolean(normalized?.path) && !normalized.path!.trim()) {
    delete normalized.path;

    normalized.index = true;
  }

  return normalized;
};

export default normalizeRoute;

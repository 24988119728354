import type { LiveNotificationEvent } from './contracts';

const parseToWSNamespace = (event: LiveNotificationEvent): string => {
  return `dh.jasmin.notification.${event}.delivery.live`;
};

const removeWSNamespace = (event: string): LiveNotificationEvent => {
  return /dh\.jasmin\.notification\.(.+)\.delivery\.live/gm.exec(event)![1] as LiveNotificationEvent;
};

export { parseToWSNamespace, removeWSNamespace };

import type { CookieAttributes } from 'js-cookie';
import env from 'utils/environment';

import location from '../routing/location';

const defaultExpiration = 2592000; // 30 days in seconds

const secondsToMilliseconds = (seconds: number): number => seconds * 1000;

const mountDate = (seconds: number): Date => new Date(new Date().getTime() + secondsToMilliseconds(seconds));

const cookiesAttributes = (expires: number = defaultExpiration): CookieAttributes => {
  const domain =
    env.isLocalhost || env.isDevelopment
      ? undefined
      : `.${location.url.subdomain!.split('.').splice(-1)[0]}.${location.url.domain}`;

  return {
    expires: mountDate(expires),
    domain,
  };
};

const normalizeValue = (value: string): string => {
  if (['1', 'true'].includes(value?.toLowerCase()?.trim())) return '1';

  if (['0', 'false'].includes(value?.toLowerCase()?.trim())) return '0';

  return value;
};

export { cookiesAttributes, normalizeValue };

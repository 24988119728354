import * as pathToRegexp from 'path-to-regexp';
import parse from 'utils/parse';
import is from 'utils/is';
import type { Parameters, SearchParameters } from 'contracts';

import { normaliseObjectKeyCase } from './utils';

/**
 * @example
 * const queryStrings = [':page', ':period']
 * const params = { page: 1, period: '2021-07-21' };
 * compileQueryStrings(queryStrings, params); // returns '?page=1&sort=2021-07-21''
 */
const compileQueryStrings = (searchParams: SearchParameters = [], params: Parameters = {}): string => {
  if (!searchParams?.length) {
    return '';
  }

  const search = searchParams
    .reduce((acc, query) => {
      const key = parse.toCamelCase(query.replace(/[^\w-]/g, ''));
      const value = pathToRegexp.compile(`:${key}?`)(normaliseObjectKeyCase(params));

      if (!is.nullish(value) && value.toString().trim() !== '') {
        acc.push(`${key}=${value}`);
      }

      return acc;
    }, [] as string[])
    .join('&');

  return search ? `?${search}` : '';
};

export default compileQueryStrings;

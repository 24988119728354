import parse from 'utils/parse';

type CacheExpiration = 'session' | 'none';

interface ApplicationCache {
  readonly length: number;
  get: (key: string) => ReturnType<Storage['getItem']>;
  set: (key: string, value: unknown) => ReturnType<Storage['setItem']>;
  has: (key: string) => boolean;
  remove: (key: string) => ReturnType<Storage['removeItem']>;
  clear: () => ReturnType<Storage['clear']>;
}

const storagePrefix = '@@msc/';

class Cache implements ApplicationCache {
  private readonly storage: Storage;

  private readonly prefix: string = storagePrefix;

  constructor(expiration: CacheExpiration = 'none', prefix = storagePrefix) {
    this.storage = expiration === 'session' ? window.sessionStorage : window.localStorage;

    this.prefix = prefix;
  }

  private parseKey(key: string): string {
    return `${this.prefix}${parse.toKebabCase(key, '.')}`;
  }

  get<T = ReturnType<Storage['getItem']>>(key: string): T {
    return JSON.parse(this.storage.getItem(this.parseKey(key)) ?? null!) as T;
  }

  set(key: string, value: unknown): ReturnType<Storage['setItem']> {
    return this.storage.setItem(this.parseKey(key), JSON.stringify(value));
  }

  has(key: string): boolean {
    return Boolean(this.storage[this.parseKey(key)]);
  }

  remove(key: string): ReturnType<Storage['removeItem']> {
    return this.storage.removeItem(this.parseKey(key));
  }

  clear(): ReturnType<Storage['clear']> {
    return this.storage.clear();
  }

  get length(): number {
    return Object.keys(this.storage).filter((key) => key.startsWith(this.prefix)).length;
  }
}

export type { ApplicationCache, CacheExpiration };
export default Cache;

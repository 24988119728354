import parse from 'utils/parse';
import tree from 'utils/tree';
import type { Route } from 'contracts';

import reactRouterToPathRegexp from './react-router-to-path-regexp';
import regexpPathToReactRouter from './regexp-path-to-react-router';
import normalizeRoute from './normalize-route';

const mountTree = (routes: Array<Route>, onRoutePath: (route: Route) => void): Array<Route> => {
  return tree.mount(routes, {
    onMountParent: (prev, next) => {
      const path = parse.path(prev?.path ?? '', next.path ?? '');

      if (path.trim()) {
        onRoutePath({ ...next, path: reactRouterToPathRegexp(path) });
      }

      return { ...next, path };
    },
    onNodePush(entry) {
      return regexpPathToReactRouter(entry.path).map((item) => {
        return normalizeRoute({
          ...entry,
          path: item,
          children: entry.children?.length ? entry.children : undefined,
        });
      });
    },
  });
};

export default mountTree;

import invariant from 'invariant';
import toArray from 'utils/to-array';
import tree from 'utils/tree';
import type { Route } from 'contracts';

import mountTree from './utils/mount-tree';
import normalizeRoute from './utils/normalize-route';
import validate from './utils/validate';

type Module = Record<string, Route | Array<Route>>;

const modules = new Set<Route>();
const paths: Record<string, Pick<Route, 'searchParams' | 'hashParams' | 'replace'> & Required<Pick<Route, 'path'>>> =
  {};

const addModule = (module: Module): void => {
  tree
    .flatten(toArray(module.default))
    .map(normalizeRoute)
    .map(validate)
    .forEach((r: Route) => modules.add(r));
};

if (process.env.NODE_ENV === 'test') {
  const defaultRoutes: Array<Route> = [
    {
      name: 'placeholder',
      path: '/:lang?/placeholder/:view(foo|bar)?/:id?',
      searchParams: [':filterBy', ':orderBy'],
      element: null,
    },
    {
      name: 'search',
      path: '/:lang?/search/:scope?',
      searchParams: [':term', ':page'],
      element: null,
    },
    {
      name: 'subscriptions',
      path: '/:lang?/subscriptions/:type(active|deactivated)/:id?',
      searchParams: [':filterBy', ':orderBy'],
      element: null,
    },
  ];

  addModule({ default: defaultRoutes });

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const files = require('glob').globSync('src/pages/**/@@global/routes.*(tsx|ts)') as string[];

  files.forEach((file) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/restrict-template-expressions
    const filepath = file.replace(`src${require('path').sep}`, '');

    // eslint-disable-next-line @typescript-eslint/no-require-imports,import/no-dynamic-require
    addModule(require(filepath) as Module);
  });
} else {
  const context = require.context('../../pages', true, /\/@@global\/routes\.(ts|tsx)$/u);

  context
    .keys()
    .filter((path) => path.startsWith('./'))
    .map(context)
    .forEach((module) => {
      addModule(module as Module);
    });
}

const routes = mountTree(Array.from(modules), ({ name, path, searchParams, hashParams, replace }) => {
  invariant(
    !paths[name],
    `[ROUTER]: Route "${name}" is already listed for the path "${paths[name]?.path}".\n\n
      Please, add a unique "name" for the route path "${path!}"\n\n`
  );

  paths[name] = { path: path!, searchParams, hashParams, replace };
});

export { paths };
export default routes;

type RouterRegexp = [regexp: RegExp, replacement: string];

const expressions: Array<RouterRegexp> = [[/\*/g, '(.*)']];

const reactRouterToPathRegexp = (path: string): string => {
  if (!path) return path;

  return expressions.reduce((normalized, [regexp, replacement]) => {
    return normalized.replace(regexp, replacement);
  }, path);
};

export default reactRouterToPathRegexp;

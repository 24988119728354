import gTag from './g-tag';
import { convertKeyToSnakeCase, purgeNullishEntries } from './utils';
import { TRACKING_ID } from './tracking-id';
import type { DefineTracking, TrackingArgument } from './contracts';

const sendTracking = <T extends TrackingArgument>(
  tracking: DefineTracking<T>[keyof DefineTracking<T>],
  values?: Record<string, string>
): void => {
  if (!tracking) return;

  const { action, description, category, label, value, nonInteraction } = tracking?.format?.(values) ?? tracking;

  if (!description?.trim()) {
    throw Error(
      `[Google Analytics] Missing "description" for tracking:
- category: ${category}
- label: ${label}
- action: ${action}`
    );
  }

  gTag(
    'event',
    action,
    convertKeyToSnakeCase(
      purgeNullishEntries({
        eventCategory: category,
        eventLabel: label,
        value,
        nonInteraction,
        sendTo: TRACKING_ID,
      })
    )
  );
};

export { sendTracking };

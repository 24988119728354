import is from 'utils/is';

import gTag from './g-tag';
import { TRACKING_ID } from './tracking-id';
import { convertKeyToSnakeCase } from './utils';

const sendPageView = (title: string, language: string): void => {
  if (is.nullish(title)) {
    return;
  }

  const langParam = new RegExp(`^/${language}/?`, 'i');

  gTag(
    'event',
    'page_view',
    convertKeyToSnakeCase({
      pageTitle: title,
      pagePath: window.location.pathname.replace(langParam, '/'),
      pageLocation: window.location.href,
      sendTo: TRACKING_ID,
    })
  );
};

export default sendPageView;

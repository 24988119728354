import { catchError, defer, distinctUntilChanged, interval, map, of, retry } from 'rxjs';
import type { Observable } from 'rxjs';
import Cookies from 'js-cookie';
import type { CookieAttributes } from 'js-cookie';
import cookies from 'utils/cookies';
import {
  createCookieConsent,
  createCookieConsentConfigurationCookies,
  findCookieConsentConfigurations,
} from 'services/api/proxy-cookie-consent/consent';
import type {
  BulkCreateConsentConfigurationCookieRequest,
  CookieCategory,
  CookieConsentConfiguration,
  CookieConsentResponse,
  CreateCookieConsentRequest,
} from 'services/api/proxy-cookie-consent/data-contracts';
import location from 'services/routing/location';
import env from 'utils/environment';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';

import Store from './store';

type CookieConsentCategory =
  | 'analytical'
  | 'essential'
  | 'advertising'
  | 'performance'
  | 'functionality'
  | 'marketing_targeting';

type CookieCategoriesByName = Record<string, CookieConsentCategory>;

type CookieCategorySelections = Partial<Record<CookieConsentCategory, boolean>>;

type CookieConsentStatus = 'incomplete' | 'complete';

interface CookieConsentUserSettings {
  updatedAt: string;
  categories: CookieCategorySelections;
}

interface CookieConsentStore {
  config?: CookieConsentConfiguration;
  userSettings?: CookieConsentUserSettings;
}

const cookieExpiration = new Date();

cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1);

const consentCookieInfo = {
  name: cookies.nameCreator('essential', 'cookieConsent'),
  attributes: (): CookieAttributes => {
    const domain = !env.isLocalhost
      ? `.${location.url.subdomain!.split('.').splice(-1)[0]}.${location.url.domain}`
      : undefined;

    return {
      expires: cookieExpiration,
      domain,
    };
  },
};

class CookieConsent extends Store<CookieConsentStore | undefined> {
  source$ = defer(() => {
    // flags store as loading
    super.meta.setLoading(true);

    return findCookieConsentConfigurations();
  }).pipe(
    retry({ count: 3, delay: (err, count) => interval(count * 1000), resetOnSuccess: true }),
    map((response) => response.data.data?.items?.[0]),
    map((config) => {
      const cookieData = Cookies.get(consentCookieInfo.name);
      const userSettings = cookieData ? (JSON.parse(cookieData) as CookieConsentUserSettings) : undefined;
      const store: CookieConsentStore = {
        config,
        userSettings,
      };

      return store;
    }),
    catchError(() => of(undefined))
  );

  constructor() {
    super({ name: 'cookie-consent', initialState: undefined });
  }

  /**
   * On CookieConsent Data change.
   * @description Event stream to get notified when CookieConsent data changes.
   * It does not emit when data is nullish.
   */
  get onChange$(): Observable<CookieConsentStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  /**
   * On Categories Data change.
   * @description Event stream to get notified when CookieConsent categories change.
   */
  get onCategoriesChange$(): Observable<CookieConsentCategory[] | undefined> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map((data) => data.config?.cookies || []),
      map((configCookies) => configCookies.map((cookie) => cookie.category)),
      map((categories) => categories.filter((category) => Boolean(category.length))),
      map((categories) => [...new Set(categories)]),
      catchError(() => of(undefined))
    );
  }

  /**
   * On Cookies Data change.
   * @description Event stream to get notified when CookieConsent known cookies change.
   */
  get onCookiesChange$(): Observable<CookieCategoriesByName | undefined> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map((data) => data.config?.cookies || []),
      map((configCookies) => {
        return configCookies.reduce((acc, cookie) => {
          return {
            ...acc,
            [cookie.name]: cookie.category,
          };
        }, {} as CookieCategoriesByName);
      }),
      catchError(() => of(undefined))
    );
  }

  get onStatusChange$(): Observable<CookieConsentStatus | undefined> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map((data) => {
        const { config, userSettings } = data;

        if (!config || !userSettings) return 'incomplete';

        const configCategories = config.cookies?.map((cookie) => cookie.category) || [];
        const notFound = configCategories.find((category) => !(category in userSettings.categories));

        if (notFound) return 'incomplete';

        return 'complete';
      }),
      catchError(() => of(undefined))
    );
  }

  updateConsentSignature$(url: string, selections: CookieCategorySelections): Observable<CookieConsentResponse> {
    const consentConfigurationId = this.data?.config?.id || '';
    const categories: CookieCategorySelections = {
      ...selections,
      essential: true,
    };
    const approvedCategoryList = Object.keys(categories).filter((category) => Boolean(categories[category]));
    const request: CreateCookieConsentRequest = {
      url,
      consentConfigurationId,
      approvedCategories: approvedCategoryList as CookieCategory[],
    };

    return createCookieConsent(request).pipe(
      map((response) => {
        this.storeUserSettings(categories);

        return response.data;
      })
    );
  }

  storeUserSettings(categories: CookieCategorySelections): void {
    const userSettings: CookieConsentUserSettings = {
      updatedAt: new Date().toISOString(),
      categories,
    };

    Cookies.set(consentCookieInfo.name, JSON.stringify(userSettings), consentCookieInfo.attributes());
    this.set('userSettings', userSettings);
  }

  reportUnknownCookies$(unknownCookies: string[]): Observable<void> {
    const consentConfigurationId = this.data?.config?.id || '';
    const payload: BulkCreateConsentConfigurationCookieRequest = {
      cookies: unknownCookies.map((cookie) => ({ name: cookie })),
    };

    return createCookieConsentConfigurationCookies(consentConfigurationId, payload).pipe(map(() => undefined));
  }

  configurationId(): string | undefined {
    return this.data?.config?.id;
  }
}

export type {
  CookieConsentCategory,
  CookieCategoriesByName,
  CookieCategorySelections,
  CookieConsentUserSettings,
  CookieConsentStore,
};
export default new CookieConsent();

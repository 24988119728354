import type { Pathname, Search } from 'history';
import type { HistoryLocation } from 'contracts';
import history from 'services/routing/history';

const createLocationDescriptor = (pathname: Pathname, search: Search = '', hash = ''): HistoryLocation => ({
  key: '',
  hash: hash ?? '',
  pathname,
  search,
  state: {
    from: history.location,
  },
});

export default createLocationDescriptor;

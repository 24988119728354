import { matchRoutes } from 'react-router-dom';
import memoizee from 'memoizee';
import type { RouteObject } from 'react-router-dom';
import is from 'utils/is';
import type { Route } from 'contracts';

const matchRoute = memoizee((routes: Array<Route>, path: string): Route | undefined => {
  if (is.nullish(routes) || is.nullish(path)) return undefined;

  const matches = matchRoutes(routes as Array<RouteObject>, path);

  if (matches == null) return undefined;

  for (let i = matches.length - 1; i >= 0; i -= 1) {
    const match = matches[i];

    if (match.route.element !== undefined) {
      return match.route as Route;
    }
  }
});

export default matchRoute;

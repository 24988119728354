import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import settings from 'configurations/application';
import language from 'services/i18n/language';
import ff from 'services/feature-toggle';
import is from 'utils/is';
import axios from 'services/axios-instance';
import uri from 'utils/uri';

enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

type QueryParams = Record<string | number, unknown>;

interface FetchOptions extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url'> {
  /** Request body content type */
  contentType?: ContentType;
  /** Query Params/Search Params */
  query?: QueryParams;
  /** Request body */
  body?: unknown;
}

type RequestParams = Omit<FetchOptions, 'body' | 'method' | 'query' | 'path'>;

type FetchResponse<T> = Observable<Omit<AxiosResponse<T>, 'config'>>;

const mountFormData = (content: Record<string, unknown>): FormData => {
  return Object.keys(content ?? {}).reduce((formData, key) => {
    const property = content[key];

    if (property instanceof Blob) {
      formData.append(key, property);
    } else if (is.object(property)) {
      formData.append(key, JSON.stringify(property));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-base-to-string
      formData.append(key, (property ?? '').toString());
    }

    return formData;
  }, new FormData());
};

const fetch = <T = unknown>(path: string, options: FetchOptions): FetchResponse<T> => {
  const instance = axios;

  const controller = new AbortController();
  const fetchOptions = { ...options };

  if (fetchOptions.contentType === ContentType.FormData && is.object(fetchOptions.body)) {
    fetchOptions.body = mountFormData(fetchOptions.body);
  }

  const headers = {
    ...(fetchOptions.headers as Record<string, unknown>),
    'Accept-Language': language.current,
  };

  if (uri.relative(path)) {
    headers['X-Feature-Toggles'] = ff.toString();
  }

  const observable$ = new Observable<AxiosResponse<T>>((subscriber) => {
    instance
      .request<T>({
        ...fetchOptions,
        baseURL: uri.relative(path) ? settings.envVars.apiGwBaseUri : undefined,
        headers,
        params: fetchOptions.query,
        data: fetchOptions.body,
        url: path,
        signal: controller.signal,
      })
      .then((response) => {
        subscriber.next(response);
        subscriber.complete();

        return response;
      })
      .catch((error) => {
        subscriber.error(error);

        return error;
      });
  });

  const subscribe = observable$.subscribe.bind(observable$);

  // eslint-disable-next-line promise/always-return
  observable$.subscribe = (...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const subscription = subscribe(...args);
    const unsubscribe = subscription.unsubscribe.bind(subscription);

    subscription.unsubscribe = () => {
      controller.abort();

      unsubscribe();
    };

    return subscription;
  };

  return observable$;
};

export type { RequestParams, FetchOptions, FetchResponse };
export { ContentType };
export default fetch;

import { combineLatest, map } from 'rxjs';
import type { NavigationMenuItem } from 'contracts';
import tree from 'utils/tree';
import toArray from 'utils/to-array';
import is from 'utils/is';

type Module = Record<string, NavigationMenuItem>;

const modules: Array<NavigationMenuItem> = [];

const addModule = (module: Module): void => {
  tree.flatten(toArray(module.default)).forEach((r: NavigationMenuItem) => modules.push(r));
};

if (process.env.NODE_ENV === 'test') {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const files = require('glob').globSync('src/pages/**/@@global/menu.*(tsx|ts)') as string[];

  files.forEach((file) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-require-imports
    const filepath = file.replace(`src${require('path').sep}`, '');

    // eslint-disable-next-line @typescript-eslint/no-require-imports, import/no-dynamic-require
    addModule(require(filepath) as Module);
  });
} else {
  const context = require.context('../../pages', true, /\/@@global\/menu\.(ts|tsx)$/u);

  context
    .keys()
    .filter((path) => path.startsWith('./'))
    .map(context)
    .forEach((module) => {
      addModule(module as Module);
    });
}

const { length } = modules;

const menu = tree.mount(
  modules.sort((a, b) => {
    const weightA = is.func(a.weight) ? a.weight(length) : a.weight;
    const weightB = is.func(b.weight) ? b.weight(length) : b.weight;

    return (weightA ?? length) - (weightB ?? length);
  }),
  {
    onNodePush(entry) {
      const visibility$ =
        entry?.visibility$ ??
        (entry.children?.length
          ? combineLatest(entry.children.map((c) => c.visibility$).filter(Boolean)).pipe(
              map((access) => access.some(Boolean))
            )
          : undefined);

      return {
        ...entry,
        visibility$,
      };
    },
  }
);

export default menu;

import React from 'react';
import { captureException as SentryCaptureException } from 'services/sentry';
import { sendException as GASendException } from 'services/google-analytics';

interface ErrorBoundaryProperties {
  fallback: React.ReactNode;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  error: unknown;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProperties, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProperties) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): Record<string, unknown> {
    return { error };
  }

  componentDidCatch(error: Error): void {
    console.error(error);

    GASendException(error.toString());

    SentryCaptureException(error, {
      tags: {
        produces: 'internal-error',
        origin: 'ErrorBoundary',
      },
    });
  }

  render(): React.ReactNode {
    const { fallback, children } = this.props;
    const { error } = this.state;

    if (error) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;

import type { HashParameters, Parameters, SearchParameters } from 'contracts';
import language from 'services/i18n/language';

import mountRoutePath from './mount-route-path';

const createRoutePath = (
  path: string,
  params: Parameters = {},
  searchParams: SearchParameters = [],
  hashParams: HashParameters = [],
  replace = false
): ReturnType<typeof mountRoutePath> => {
  const globalParams = { lang: language.current };

  const mounted = mountRoutePath(path, { ...globalParams, ...params }, searchParams, hashParams)!;

  if (replace) {
    mounted.state = mounted.state?.from?.state;
  }

  return mounted;
};

export default createRoutePath;

import { BehaviorSubject, throwError, catchError, tap } from 'rxjs';
import type { Subject, Observable } from 'rxjs';
import { captureException as SentryCaptureException } from 'services/sentry';
import deepCopy from 'utils/deep-copy';
import is from 'utils/is';

/**
 * @global
 * @typedef {object} Logger
 * @property @function log
 * @property @function warn
 * @property @function error
 * @property @function fatal
 */
interface Logger {
  info(message: unknown, origin: string): void;
  warn(message: unknown, origin: string): void;
  error(message: unknown, origin: string): void;
  fatal(message: unknown, origin: string): void;
}

/**
 * @global
 * @typedef {object} MetadataActions
 * @property @function setLoading
 * @property @function setError
 * @property @function reset
 */
interface MetadataActions {
  setLoading(status: boolean): void;
  setError(error: ServiceError): void;
  reset(): void;
}

/**
 * @global
 * @typedef {object} ServiceError
 * @property {any} details
 * @property {number} timestamp
 */
interface ServiceError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  timestamp: number;
}

/**
 * @global
 * @typedef {object} ServiceMetadata
 * @property {boolean} loading
 * @property {ServiceError | undefined} error
 */
interface ServiceMetadata {
  loading: boolean;
  error: ServiceError | undefined;
}

/**
 * @global
 * @typedef ServiceDataType<T>
 * @template T
 */
type ServiceDataType<T> = T extends undefined ? T | undefined : T;

/**
 * @global
 * @typedef {object} ServiceBaseSettings
 * @property {T=} initialState
 * @property {Subject<T>=} subject$
 * @template T
 */
interface ServiceBaseSettings<T> {
  /**
   * Set BehaviourSubject initial state
   */
  initialState?: T;
  /**
   * Overwrite Service's Default Subject (BehaviourSubject)
   */
  subject$?: Subject<ServiceDataType<T>>;
}

/**
 * Service Base
 * @description Reactive application state management
 * @abstract
 * @template T
 */
abstract class ServiceBase<T = never> {
  /**
   * Service Name
   * @description Used for tracing (debug) purpose.
   * @abstract
   * @readonly
   */
  abstract readonly name: string;

  /**
   * RxJs Service store
   * @description Controls Service's data event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   * @readonly
   */
  private readonly store$: Subject<ServiceDataType<T>>;

  /**
   * RxJs Service Metadata Behaviour Subject
   * @description Controls service's Metadata event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   * @readonly
   */
  private readonly meta$ = new BehaviorSubject<ServiceMetadata>({
    loading: false,
    error: undefined,
  });

  /**
   * Internal Service Data
   * @property {T}
   * @private
   * @template {T}
   */
  private storeData: ServiceDataType<T>;

  /**
   * For debugging purpose
   * Identify the type of the "handler"
   */
  public readonly type = 'Service';

  /**
   * Service Data
   * @description Access service's current data set.
   * @protected
   */
  protected get data(): ServiceDataType<T> {
    return this.storeData;
  }

  /**
   * Flags is Service is loading
   * @protected
   */
  protected get loading(): boolean {
    return this.meta$.value.loading;
  }

  /**
   * Service Error Data
   * @description If "source$" by any means throws an error, this is the place you will get more details about,
   * otherwise value will be always "undefined".
   * @protected
   */
  protected get error(): ServiceError | undefined {
    return this.meta$.value.error;
  }

  /**
   * On Service Metadata change.
   * @description Event stream to get notified when Service Metadata changes.
   * @protected
   */
  protected get onMetaChange$(): Observable<ServiceMetadata> {
    return this.meta$.asObservable();
  }

  /**
   * On Service Data change.
   * @description Event stream to get notified when Store Data changes.
   * @protected
   */
  protected get onChange$(): Observable<ServiceDataType<T>> {
    return this.store$.asObservable();
  }

  /**
   * Event/Message Logger
   * @protected
   */
  protected get log(): Logger {
    return {
      info(): void {
        // TBD
      },
      warn(): void {
        // TBD
      },
      error(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'error',
            origin,
          },
        });
      },
      fatal(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'fatal',
            origin,
          },
        });
      },
    };
  }

  /**
   * Service Metadata
   * @type {MetadataActions}
   * @protected
   */
  protected get meta(): MetadataActions {
    const { meta$, name, log } = this;

    return {
      setLoading(status) {
        meta$.next({
          ...meta$.value,
          loading: status,
        });
      },
      setError(error) {
        log.error(error.details, name);

        meta$.next({
          ...meta$.value,
          error,
          loading: false,
        });
      },
      reset() {
        meta$.next({ loading: false, error: undefined });
      },
    };
  }

  constructor(settings?: ServiceBaseSettings<T>) {
    const { initialState, subject$ } = settings || {};

    this.store$ = subject$ ?? new BehaviorSubject<ServiceDataType<T>>(initialState as ServiceDataType<T>);
    this.storeData =
      this.store$ instanceof BehaviorSubject
        ? (deepCopy(this.store$.value) as ServiceDataType<T>)
        : (undefined as ServiceDataType<T>);
  }

  /**
   * Update stored data
   * @param {T} data
   * @protected
   */
  protected set(data: ServiceDataType<T> | ((value: ServiceDataType<T>) => ServiceDataType<T>)): void {
    const result = is.func(data) ? data(this.storeData) : data;

    this.storeData = deepCopy(result);
    this.store$.next(result);
  }

  /**
   * Higher Order Observable
   * @param {Observable<D>} observable$
   * @protected
   * @template D
   */
  protected call$<D>(observable$: Observable<D>): Observable<D> {
    this.meta.setLoading(true);

    return observable$.pipe(
      tap(() => this.meta.setLoading(false)),
      catchError((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.meta.setError({ details: error, timestamp: new Date().getTime() });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return throwError(() => error);
      })
    );
  }
}

export type { ServiceError };
export default ServiceBase;

import { asyncScheduler, asapScheduler } from 'rxjs';
import invariant from 'invariant';
import is from 'utils/is';
import typeOf from 'utils/type-of';
import type { Task } from 'contracts';

const pool = new Set<Task>();

const schedule = (task: Task): void => {
  if (!pool.has(task) || !is.number(task.interval)) return;

  const scheduler = task?.type === 'asap' ? asapScheduler : asyncScheduler;

  scheduler.schedule(() => {
    task.onBeforeStart?.();

    void task.run().finally(() => {
      task.onFinish?.();

      if (!task.replay) {
        pool.delete(task);
      }

      return schedule(task);
    });
  }, task.interval);
};

interface TaskScheduler {
  register: (task: Task) => void;
  unregister: (task: Task) => void;
  has: (task: Task) => boolean;
}

const validate = (task: Task): void => {
  invariant(is.object(task), `[Scheduler]: Expected task to be an object, but instead got "${typeOf(task)}"`);

  invariant(is.func(task.run), `[Scheduler]: Expected task.run() to be async, but instead got "${typeOf(task.run)}"`);
};

const scheduler: TaskScheduler = {
  register(task: Task) {
    if (scheduler.has(task)) return;

    validate(task);

    pool.add(task);

    schedule(task);
  },
  unregister(task: Task) {
    pool.delete(task);
  },
  has(task: Task) {
    return pool.has(task);
  },
};

export default scheduler;

import settings from 'configurations/application';

const { defaultLanguage, defaultLocales } = settings.i18n;

const getLanguageFromLocale = (id: string): string => id.split(/[_-]+/u)[0];

const getBrowserLanguages = (): readonly string[] =>
  navigator.languages || [navigator.language || defaultLocales[defaultLanguage]];

const getBrowserPreferredLanguage = (): string | undefined => {
  return getBrowserLanguages()
    .map(getLanguageFromLocale)
    .find((lang) => settings.i18n.languages.includes(lang.toLowerCase()));
};

/**
 * The locale consists of optional languages, country and variant fields in that oder,
 * separated by underscore, followed by an optional keywords.
 * For example, “en_US”, “sy_Cyrl_YU”, “zh_pinyin”
 * @see https://en.wikipedia.org/wiki/ISO_639
 * @param {string} lang
 */
const getBrowserPreferredLocale = (lang: string): string =>
  getBrowserLanguages().find((id: string) => getLanguageFromLocale(id) === lang) || defaultLocales[lang];

export { getBrowserLanguages, getLanguageFromLocale, getBrowserPreferredLanguage, getBrowserPreferredLocale };

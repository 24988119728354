import type React from 'react';
import type { MatchResult } from 'path-to-regexp';
import { match } from 'path-to-regexp';
import createLocationDescriptor from 'services/navigation/create-location-descriptor';
import history from 'services/routing/history';
import routes, { paths } from 'services/routing/routes';
import uri from 'utils/uri';
import parse from 'utils/parse';
import is from 'utils/is';
import type { Search } from 'history';
import type { BrowserHistory, Route, Parameters, ApplicationRoute } from 'contracts';

import matchRoute from './utils/match-route';

type HTMLAnchor = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type NamedRoute = Omit<Route, 'name'> & { name: keyof ApplicationRoute };

interface LocationUrl {
  host: string;
  hostname: string;
  domain: string;
  subdomain: string | null;
  pathname: string;
  protocol: string;
  localhost: boolean;
  origin: string;
}

interface Location {
  readonly current: NamedRoute;
  readonly previous: undefined | NamedRoute;
  params: () => Parameters;
  searchParams: (globalParams?: boolean) => Parameters;
  hashParams: (globalParams?: boolean) => Parameters;
  readonly url: LocationUrl;
  navigate: (url: string | undefined, target?: HTMLAnchor['target'], replace?: boolean) => void;
}

const getRouteFromPath = (path: string): NamedRoute | undefined => {
  const route = matchRoute(routes, path);

  if (!route) return;

  return {
    ...route,
    name: route.name as keyof ApplicationRoute,
    path: paths[route.name]?.path,
  } satisfies NamedRoute;
};

const location: Location = {
  get current() {
    return getRouteFromPath(history.location.pathname)!;
  },
  get previous() {
    const { from } = (history as unknown as BrowserHistory).location.state || {};

    if (!from) {
      return;
    }

    return getRouteFromPath(from.pathname);
  },
  params() {
    const route = location.current;

    const { params = {} } = match(route?.path || '', {
      decode: decodeURIComponent,
    })(history.location.pathname) as MatchResult<Record<string, string>>;

    return params;
  },
  searchParams(globalParams = false) {
    const route = location.current;
    const { searchParams = [] } = route;
    const { search } = history.location;

    const normalizedSearchParams = searchParams.map((s) => parse.toCamelCase(s.replace(/[^\w-]/g, '')));

    if (!search?.trim()) return {};

    return search
      .replace(/^\?/g, '')
      .split('&')
      .filter((query) => {
        const [key] = query.split('=');

        return globalParams ? true : normalizedSearchParams.includes(parse.toCamelCase(key));
      })
      .reduce((acc, query) => {
        const { 0: key, 1: value } = query.split('=');

        return { ...acc, [parse.toCamelCase(key)]: decodeURIComponent(value) };
      }, {} satisfies Parameters);
  },
  hashParams(globalParams = false) {
    const route = location.current;
    const { hashParams = [] } = route;
    const { hash } = history.location;

    const normalizedHashParams = hashParams.map((h: string) => parse.toCamelCase(h));
    const locationHash = hash?.split('#').map((h) => parse.toCamelCase(h)) ?? [];

    return locationHash
      .filter(Boolean)
      .filter((h) => (globalParams ? true : normalizedHashParams.includes(h)))
      .reduce((acc, next) => ({ ...acc, [next]: 'true' }), {} satisfies Parameters);
  },
  /**
   *  URL format composition:
   * ┌─────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
   * │                                                   href                                                      │
   * ├──────────┬──┬─────────────────────┬─────────────────────────────┬───────────────────────────────────┬───────┤
   * │ protocol │  │        auth         │             host            │           path                    │ hash  │
   * │          │  │                     ├─────────────────────────────┬──────┼──────────┬─────────────────┤       │
   * │          │  │                     │           hostname          │ port │ pathname │     search      │       │
   * │          │  │                     │                             │      │          ├─┬───────────────┤       │
   * │          │  │                     │                             │      │          │ │    query      │       │
   * "  https:   //    user   :   pass   @     sub     .  example.com  : 8080   /p/a/t/h  ?  query=string    #hash "
   * │          │  │          │          │         hostname            │ port │          │                 │       │
   * │          │  │          │          ├─────────────────────────────┴──────┤          │                 │       │
   * │ protocol │  │ username │ password │  subdomain  |     domain    │      │ pathname │                 │       │
   * │          │  │          │          ├─────────────────────────────┴──────┤          │                 │       │
   * │ protocol │  │ username │ password │                host                │          │                 │       │
   * ├──────────┴──┼──────────┴──────────┼────────────────────────────────────┤          │                 │       │
   * │   origin    │                     │               origin               │ pathname │      search     │ hash  │
   * ├─────────────┴─────────────────────┴────────────────────────────────────┴──────────┴─────────────────┴───────┤
   * │                                                   href                                                      │
   * └─────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
   */
  /**
   * Location Url
   * @returns {LocationUrl}
   */
  get url(): LocationUrl {
    const { protocol, host, hostname, pathname, origin } = window.location;

    const isIPAddress = uri.ip(hostname);

    return {
      protocol,
      host,
      hostname,
      origin,
      get domain(): string {
        if (isIPAddress) {
          return hostname;
        }

        return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
      },
      get subdomain(): string | null {
        if (isIPAddress) {
          return null;
        }

        const subdomain = hostname.replace(this.domain as string, '');

        return subdomain.endsWith('.') ? subdomain.substring(0, subdomain.lastIndexOf('.')) : subdomain;
      },
      get localhost(): boolean {
        return hostname === 'localhost' || hostname === '127.0.0.1';
      },
      pathname,
    };
  },
  navigate: (url, target: HTMLAnchor['target'], replace = false): void => {
    if (is.nullish(url)) return;

    if (target === '_blank' || uri.external(url)) {
      if (replace) {
        window.location.replace(url);

        return;
      }

      window.open(url, target || '_blank');

      return;
    }

    let sanitizedUrl = uri.absolute(url) ? url.replace(/^.*\/\/[^/]+/, '') : url;
    const search: Search = /\?.+/g.exec(sanitizedUrl)?.[0] ?? '';

    sanitizedUrl = sanitizedUrl.replace(search, '');

    const locationDescriptor = createLocationDescriptor(sanitizedUrl, search);

    if (replace) {
      locationDescriptor.state = locationDescriptor.state?.from?.state;

      return history.replace(locationDescriptor);
    }

    history.push(locationDescriptor);
  },
};

export type { Location };
export default location;

import { BehaviorSubject, of, distinctUntilChanged, switchMap, shareReplay } from 'rxjs';

import history from './history';
import location from './location';

const getCurrentURI = (): string => window.location.href.replace(window.location.protocol, '');

const navigation$ = new BehaviorSubject<string>(getCurrentURI());

history.listen(() => {
  navigation$.next(getCurrentURI());
});

const onHistoryChange$ = navigation$.pipe(
  distinctUntilChanged((prev, next) => prev === next),
  switchMap(() => of(location.current)),
  shareReplay({ bufferSize: 1, refCount: true })
);

export { onHistoryChange$ };

import gTag from './g-tag';
import { TRACKING_ID } from './tracking-id';

const sendException = (description: string, fatal?: false): void => {
  gTag('event', 'exception', {
    description,
    fatal,
    sendTo: TRACKING_ID,
  });
};

export default sendException;

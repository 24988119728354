import { ApolloClient, createHttpLink, from, split, type DefaultOptions } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import settings from 'configurations/application';

import apolloCache from './cache';
import authLink from './auth-link';
import errorLink from './error-link';

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: `${settings.envVars.graphQLEndpoint}`,
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-unsafe-assignment
  fetch: process.env.NODE_ENV === 'test' ? require('cross-fetch').fetch : undefined,
});

const batchHttpLink = new BatchHttpLink({
  uri: `${settings.envVars.graphQLEndpoint}`,
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-unsafe-assignment
  fetch: process.env.NODE_ENV === 'test' ? require('cross-fetch').fetch : undefined,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

const httpCallLinkSplit = split((operation) => operation.getContext().important === true, httpLink, batchHttpLink);

const client = new ApolloClient({
  link: from([errorLink, authLink, httpCallLinkSplit]),
  cache: apolloCache,
  defaultOptions,
});

export default client;
